import React, { createContext, useContext, useState } from "react";
import { v4 as uuid4 } from 'uuid';


const NotificationContext = createContext();


export function useNotifications() {
    return useContext(NotificationContext);
}


export function NotificationProvider({ children }) {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, title) => {
        setNotifications((prev) => [
            ...prev,
            { id: uuid4(), message, title }
        ]);
    };

    const removeNotification = (id) => {
        setNotifications((prev) => prev.filter(notification => notification.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    );
}
