import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import NotificationMenu from './NotificationMenu';
import { useNotifications } from './NotificationProvider';

function NotificationIcon() {
  const { notifications } = useNotifications();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton 
        color="inherit" 
        onClick={handleClick}
        sx={{ marginLeft: '8px', marginRight: '8px' }} // Adicionando margens laterais
      >
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // Aqui, usamos uma classe customizada para estilização
        sx={{ 
          '& .MuiPaper-root': { 
            maxHeight: 48 * 4.5, // Altura máxima
            width: '300px' // Largura customizada
          }
        }}
      >
        <NotificationMenu notifications={notifications} />
      </Menu>
    </>
  );
}

export default NotificationIcon;
