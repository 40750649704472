import { Alert, Box, Button, Container, IconButton, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LightCRMLogo from '../images/octupuslogo-semfundo-roxo.png';
import { confirmResetPassword } from "../services/SrvLogin";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const RedefinirSenha = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [serverity, setSeverity] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate()

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
        if (serverity === 'success') {
            navigate('/')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        await confirmResetPassword(token, newPassword).then(
            (response) => {
                if (response['status'] === 200) {
                    setSeverity('success')
                } else {
                    setSeverity('error')
                }
                setAlertMessage(response['data']['message'])
                setSnackbarOpen(true)
            },
            (error) => {
                console.log(`error: ${error}`)
                setAlertMessage(error['message'])
                setSeverity('error')
                setSnackbarOpen(true)
            }
        )
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
        setTimeout(() => {}, 0)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", border: "solid 2px", marginTop: 8, padding: 2, borderRadius: 5 }}>
                <Box component="img" src={LightCRMLogo} sx={{ marginTop: 2, marginBottom: 2, width: 224, height: 224 }} />
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Typography>
                        Informe a nova senha:
                    </Typography>
                    <TextField
                        label="Nova senha"
                        type={showPassword ? 'text' : 'password'}
                        value={newPassword}
                        // size="small"
                        variant="outlined"
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        fullWidth
                        // autoFocus
                        sx={{ mt: 2 }}
                        inputProps={{
                            endadornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >Confirmar</Button>
                </Box>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={serverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Container>
    )

}

export default RedefinirSenha;