import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL
const authAPI = axios.create({ baseURL: `${baseAPI}api/auth` })

async function setLogin(usuario, senha) {

    // console.log('usuario: ', usuario)
    // console.log('senha: ', senha)

    const loginData = {
        'username': usuario,
        'password': senha
    }

    // console.log('logindata: ', loginData)

    const resp = await authAPI.post('/login/', loginData)

    return resp.data
}

async function getHello(token) {

    const authToken = `Token ${token}`
    // console.log('authToken', authToken)
    const resp = await authAPI.get('/hello/', { headers: { Authorization: authToken } })

    return resp.data
}

async function newNormalUser(dados) {
    const resp = await authAPI.post('/normalregister/', dados)

    return resp.data
}

async function setPassword(dados, token) {
    const authToken = `Token ${token}`
    const resp = await authAPI.post('/change-password/', dados, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function resetPassword(dados) {
    const resp = await authAPI.post('/password-reset/', dados)
    return resp.data
}

async function confirmResetPassword(resetToken, newPassword) {
    try {
        const resp = await authAPI.post(`/password-reset-confirm/${resetToken}/`, { new_password: newPassword })
        console.log(`resp no confirmreset: ${resp}`)
        return {"status": resp.status, "data": resp.data}
    } catch (error) {
        if (error.response) {
            console.log('Status: ', error.response.status);
            console.log('Data: ', error.response.data);
            if (error.response.status === 400) {
                return {"status": error.response.status, "data": error.response.data}
            } else {
                throw error
            }
        } else {
            console.log('Error: ', error.message);
            throw error;
        }
    }
}

export {
    setLogin, getHello, newNormalUser, setPassword, resetPassword, confirmResetPassword
}