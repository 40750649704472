import { AccountCircle, Email as EmailIcon, LocationCity, Phone } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Snackbar, TextField, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from 'yup';
import { delContato, getContatos, newContato, updContato } from "../../services/SrvContato";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row': {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const ContatosList = ({ nome, token, profile, isAuthenticated }) => {

    // console.log('token em ContatosList: ', token)

    const [contatos, setContatos] = useState([])
    const [open, setOpen] = useState(false)
    const [contatoAtual, setContatoAtual] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const validationSchema = yup.object({
        nome: yup.string('Informe o nome').required('Nome é obrigatório').max(150, 'Até 150 caracteres'),
        whatsapp: yup.string('Informe o whatsapp').max(11, 'Até 11 caracteres').required('Whatsapp é obrigatório'),
        email: yup.string('Informe o email').email('Informe um email válido').max(150, 'Até 150 caracteres'),
        cidade: yup.string('Informe a cidade').required('Cidade é obrigatório').max(100, 'Até 100 caracteres'),
        // assinante: yup.number().required()
    })

    var initialValues = { nome: '', whatsapp: '', email: '', cidade: '' }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            if (contatoAtual) {
                // console.log('atualizar contato: ', values);
                // console.log('contato atual: ', contatoAtual)
                values.id = contatoAtual.id
                values.assinante = contatoAtual.assinante
                atualizaContato();
            } else {
                values.assinante = profile.assinante
                novoContato()
            }

            async function atualizaContato() {
                await updContato(values, token).then(
                    (response) => {
                        setSnackbarOpen(true);
                        setOpen(false);
                        fetchContatos();
                    },
                    (error) => {
                        // console.log('Erro ao atualizar o cliente potencial', error);
                        alert(`Erro ao atualizar o cliente potencial: ${error}`);
                    }
                );
            }

            async function novoContato() {
                return await newContato(values, token).then(
                    (response) => {
                        setSnackbarOpen(true);
                        setOpen(false);
                        fetchContatos();
                    },
                    (error) => {
                        // console.log('Erro ao incluir o cliente potencial: ', error);
                        alert(`Erro ao incluir o cliente potencial: ${error}`);
                    }
                );
            }
        }
    })

    async function fetchContatos() {
        // console.log('token em fetchContatos: ', token)
        // eslint-disable-next-line
        const contatosAPI = await getContatos(token)
            .then((response) => {
                // console.log('contatos recuperados: ', response)
                setContatos(response)
            },
                (error) => {
                    // console.log('Erro ao recuperar contatos: ', error)
                    alert(`Erro ao recuperar contatos: ${error}`)
                }
            )
    }

    useEffect(() => {
        fetchContatos()
        // eslint-disable-next-line
    }, [token])

    const handleEditContato = (contato) => {
        setContatoAtual(contato)
        formik.setFieldValue('nome', contato.nome)
        formik.setFieldValue('whatsapp', contato.whatsapp)
        formik.setFieldValue('email', contato.email)
        formik.setFieldValue('cidade', contato.cidade)
        // formik.setFieldValue('assinante', contato.assinante.id)
        setOpen(true)
    }

    const handleDeleteContato = async (contato) => {
        await delContato(contato.id, token).then(
            (response) => {
                setSnackbarOpen(true);
                setOpen(false);
                fetchContatos();
            },
            (error) => {
                // console.log('Erro ao excluir o cliente potencial: ', error);
                alert(`Erro ao excluir o cliente potencial: ${error}`);
            }
        )
        // console.log('delete contato : ', contato)
    }

    const handleOpen = () => {
        formik.resetForm()
        setContatoAtual(null)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };

    const columns = [
        { field: 'nome', headerName: 'Nome', width: 210, editable: false, },
        { field: 'whatsapp', headerName: 'Whatsapp', width: 150, editable: false, },
        { field: 'email', headerName: 'Email', width: 210, editable: false, },
        { field: 'cidade', headerName: 'Cidade', width: 150, editable: false, },

        {
            field: 'acoes', headerName: 'Ações', flex: 1, minWidth: 100, renderCell: (params) => (
                <>
                    <IconButton aria-label="Editar" size="small" onClick={() => handleEditContato(params.row)}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton aria-label="Excluir" size="small" onClick={() => handleDeleteContato(params.row)}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </>
            )
        }
    ]

    return (
        <Container>
            <Box component={Paper} sx={{ width: '100%' }} >
                <LocalizationProvider locale={ptBR}>
                    <StyledDataGrid
                        rows={contatos}
                        columns={columns}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15, 20]}
                        disableRowSelectionOnClick
                        autoHeight
                    />                
                </LocalizationProvider>
            </Box>
            <Button variant="contained" onClick={handleOpen} sx={{ marginTop: 2 }}>Novo</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: formik.handleSubmit,
                }}
            >
                <DialogTitle>Potencial Cliente</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Entre com as informações do cliente potencial.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        id='nome'
                        name='nome'
                        label="Nome"
                        InputProps={{
                            startAdornment: (
                                <AccountCircle sx={{ color: 'action.active', mr: 1 }} />
                            ),
                        }}
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                        helperText={formik.touched.nome && formik.errors.nome}
                        margin="normal" size='small'
                    />
                    <TextField
                        fullWidth
                        id='whatsapp'
                        name='whatsapp'
                        label='Whatsapp'
                        InputProps={{
                            startAdornment: (
                                <Phone sx={{ color: 'action.active', mr: 1 }} />
                            ),
                        }}
                        value={formik.values.whatsapp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.whatsapp && Boolean(formik.errors.whatsapp)}
                        helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='email'
                        name='email'
                        label="Email"
                        type="email"
                        InputProps={{
                            startAdornment: (
                                <EmailIcon sx={{ color: 'action.active', mr: 1 }} />
                            ),
                        }}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='cidade'
                        name='cidade'
                        label="Cidade"
                        InputProps={{
                            startAdornment: (
                                <LocationCity sx={{ color: 'action.active', mr: 1 }} />
                            ),
                        }}
                        value={formik.values.cidade}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                        helperText={formik.touched.cidade && formik.errors.cidade}
                        margin='normal' size='small'
                    />
                    <input
                        type="hidden"
                        id="assinante"
                        name="assinante"
                        value={profile.assinante}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type='submit'>Salvar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                Dados enviados com sucesso!
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default ContatosList
