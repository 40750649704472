import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CommentIcon from '@mui/icons-material/Comment';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../routes/Funil";
import { delCard, updCard } from "../../services/SrvCard";
import { getContato } from "../../services/SrvContato";
import CardDateTimeIndicator from "../CardDateTimeIndicator";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import StarRating from '../StarRating';
import WhatsAppLink from '../WhatsAppLink';
import CurrencyTextField from '../CurrencyTextField';
import CurrencyEditableLabel from '../CurrencyEditableLabel';
import CheckListAbordagem from '../CheckListAbordagem';
import { useNotifications } from '../Notifications/NotificationProvider';

const Cartao = ({ cartao, index, lista, token, fetchListas }) => {

    const [contato, setContato] = useState(null)
    const [showTextField, setShowTextField] = useState(false);
    const [observacoes, setObservacoes] = useState(cartao.observacoes ? cartao.observacoes : '')
    const [dataExpiracao, setDataExpiracao] = useState(new Date(cartao.data_expiracao))
    // eslint-disable-next-line
    const [dataLista, setDataLista] = useState(new Date(cartao.data_lista))
    const [rating, setRating] = useState(cartao.rating)
    const [abordagens, setAbordagens] = useState(cartao.abordagens)
    const [mouseOver, setMouseOver] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [atrasado, setAtrasado] = useState(false)
    const [atencao, setAtencao] = useState(false)
    const [valorContrato, setValorContrato] = useState(cartao.valor_contrato)

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const { addNotification } = useNotifications()

    const [, ref] = useDrag({
        type: ItemTypes.CARD,
        item: { ...cartao, index, lista },
    });

    async function fetchContato() {
        await getContato(token, cartao.contato)
            .then(
                (response) => {
                    setContato(response)
                },
                (erro) => alert(`Erro ao recuperar o contato do card: ${erro}`)
            )
    }

    useEffect(() => {
        fetchContato()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (contato) {
            const interval = setInterval(() => {
                // const dataCard = new Date(cartao.data_lista)
                // const dataExpCard = new Date(cartao.data_expiracao)
                const dataAtual = new Date()

                // const difDatasMs = dataExpCard - dataAtual
                const difDatasMs = dataExpiracao - dataAtual

                if (difDatasMs < 0) {
                    if (!atrasado) {
                        setAtrasado(true)
                        addNotification('Ação atrasada!', contato.nome)
                    }
                } else {
                    setAtrasado(false)
                    // var difTotal = dataExpCard - dataCard
                    var difTotal = dataExpiracao - dataLista
                    var perc_dif = difDatasMs / difTotal
                    // console.log('perc_dif: ', perc_dif)
                    if (perc_dif <= 0.2) {
                        if (!atencao) {
                            setAtencao(true)
                            addNotification('Prazo próximo de expiração!', contato.nome)
                        }
                    } else {
                        setAtencao(false)
                    }
                }
            }, 5000);

            return () => clearInterval(interval)
        }
    }, [dataExpiracao, dataLista, addNotification, atencao, atrasado, contato])


    // console.log('cartao.data_lista: ', cartao.data_lista)
    // console.log('cartao.data_expiracao: ', cartao.data_expiracao)
    const agendar = ((lista.nome === 'Reunião Agendada') && (cartao.data_lista === cartao.data_expiracao))
    const valorar = ((lista.nome === 'Contrato Enviado') && (cartao.valor_contrato === '0.00'))
    const valorado = (cartao.valor_contrato !== '0.00')
    const temObs = (observacoes !== '')
    // console.log('agendar: ', agendar)
    // console.log('valorar: ', valorar)

    const handleFabClick = () => {
        setShowTextField(true);
    };

    async function setCard(card) {
        const cardAtualizado = await updCard(token, card).then(
            (response) => {
                return response
            },
            (erro) => {
                // console.log('Ocorreu um erro ao atualizar o card: ', erro)
                alert(`Ocorreu um erro ao atualizar o card: ${erro}`)
                return null
            }
        )

        return cardAtualizado
    }

    const handleSaveCloseClick = () => {
        cartao.observacoes = observacoes
        setCard(cartao)
        setShowTextField(false);
    };

    const handleCloseClick = () => {
        setObservacoes(cartao.observacoes)
        setShowTextField(false);
    }

    const handleObsChange = (e) => {
        setObservacoes(e.target.value)
    }

    const handleRatingChange = async (value) => {
        cartao.rating = value
        setRating(value)
        // eslint-disable-next-line
        const cardAtualizado = await setCard(cartao)
        // eslint-disable-next-line
        const listasAtualizadas = await fetchListas()
    }

    const handleAbordagensChange = async (value) => {
        cartao.abordagens = value
        setAbordagens(value)
        // eslint-disable-next-line
        const cardAtualizado = await setCard(cartao)
        // eslint-disable-next-line
        const listasAtualizadas = await fetchListas()
    }

    const handleDataReuniaoSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const date = data.get('date');
        const time = data.get('time');

        // Combine date and time to create a Date object
        const dateTimeString = `${date}T${time}`;
        const dateTime = new Date(dateTimeString);

        cartao.data_expiracao = dateTime
        setDataExpiracao(dateTime)
        setCard(cartao)
    };

    const handleValorFormSubmit = (event) => {
        event.preventDefault();

        cartao.valor_contrato = valorContrato
        setCard(cartao)
    }

    const handleValorFormChange = (value) => {
        setValorContrato(value.target.value)
    }

    const handleValorContratoChange = (value) => {
        setValorContrato(value)
    }

    const dataToShow = dataExpiracao.toLocaleString('pt-BR', { timezone: 'America/Sao_Paulo' })

    const handleMouseOver = () => {
        setMouseOver(true)
    }

    const handleMouseOut = () => {
        setMouseOver(false)
    }

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true)
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }

    const handleDeleteCard = async () => {
        const id = cartao.id
        // console.log('excluir cartão: ', id)
        await delCard(id, token).then(
            (response) => {
                alert('Cartão excluído com sucesso!')
                setOpenDeleteDialog(false)
                fetchListas()
            },
            (erro) => {
                alert(`Ocorreu um erro ao excluir o cartão: ${erro.response.data}`)
                setOpenDeleteDialog(false)
            }
        )
    }

    // console.log('cartão: ', cartao)

    return (
        <Paper elevation={6} ref={ref} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} sx={{
            padding: '0.5rem',
            backgroundColor: 'white',
            cursor: 'move',
            margin: 1,
            borderRadius: 2,
            transition: 'transform 0.2s ease-in-out',
            border: atrasado ? '2px solid red' : (atencao ? '2px solid yellow' : '1px'),
        }}>
            {/* <ListItem ref={ref} sx={{
                padding: '0.5rem',
                backgroundColor: 'white',
                cursor: 'move',
                margin: 1,
                borderRadius: 2,
                transition: 'transform 0.2s ease-in-out',
                border: atrasado ? '2px solid red' : (atencao ? '2px solid yellow' : '1px'),
            }}> */}
            <Box>
                <Box>
                    {
                        contato ? (
                            <>
                                <Tooltip title={`whatsapp: ${contato.whatsapp} - email: ${contato.email}`} placement='top'>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} gutterBottom>
                                        {contato.nome}
                                    </Typography>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Recuperando" placement='top'>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} gutterBottom>
                                        "Recuperando"
                                    </Typography>
                                </Tooltip>
                            </>
                        )
                    }
                </Box>
                <Box>
                    {agendar && (
                        <Box
                            component="form"
                            onSubmit={handleDataReuniaoSubmit}
                            sx={{
                                // mt: 2, 
                                // position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                // width: 300,
                                margin: 'auto',
                                padding: 2,
                                border: '1px solid #ccc',
                                borderRadius: 2,
                            }}>
                            <TextField
                                id="date"
                                name="date"
                                label="Data"
                                type="date"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                id="time"
                                name="time"
                                label="Hora"
                                type="time"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <Button type="submit" variant="contained" color="primary">
                                Agendar
                            </Button>
                        </Box>
                    )}
                    {
                        valorar && (
                            <Box
                                component='form'
                                onSubmit={handleValorFormSubmit}
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    // position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    // width: 300,
                                    // margin: 'auto',
                                    padding: 2,
                                    border: '1px solid #ccc',
                                    borderRadius: 2,
                                }}
                            >
                                <CurrencyTextField
                                    label='Valor do Contrato'
                                    value='0.00'
                                    variant='outlined'
                                    id='valor_contrato'
                                    onChange={handleValorFormChange}
                                    required
                                />
                                <Button type="submit" variant="contained" color="primary">
                                    Informar
                                </Button>
                            </Box>
                        )
                    }
                    <Typography variant="caption" sx={{ color: atencao ? 'black' : 'white' }} fontSize={10} gutterBottom>
                        {atrasado ? (
                            <CardDateTimeIndicator type='danger' text={dataToShow} />

                        ) : (
                            <>
                                {atencao ? (
                                    <CardDateTimeIndicator type='warning' text={dataToShow} />
                                ) : (
                                    <CardDateTimeIndicator type='normal' text={dataToShow} />
                                )}
                            </>
                        )}
                    </Typography>
                </Box>
                <Box>
                    <IconButton color="black" aria-label="edit" onClick={handleFabClick} size="small">
                        {temObs ? (
                            <>
                                <Tooltip title="Abrir observações" placement='top'>
                                    <CommentIcon sx={{ fontSize: 15 }} />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Inserir observações" placement='top'>
                                    <AddCircleIcon sx={{ fontSize: 15 }} />
                                </Tooltip>
                            </>
                        )}
                    </IconButton>
                    {
                        mouseOver && (
                            <IconButton aria-label='delete' size='small' edge='end' onClick={handleDeleteClick}>
                                <>
                                    <Tooltip title="Excluir cartão" placement='top'>
                                        <DeleteIcon fontSize='inherit' />
                                    </Tooltip>
                                </>
                            </IconButton>
                        )
                    }
                    <Dialog
                        fullScreen={fullScreen}
                        open={openDeleteDialog}
                        onClose={handleCloseDeleteDialog}
                        aria-labelledby='delete-dialog-title'
                    >
                        <DialogTitle id="delete-dialog-title">
                            {"Confirma a exclusão do cartão?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Ao confirmar a exclusão do cartão, ele será retirado do funil. Esta operação não pode ser desfeita.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleCloseDeleteDialog}>
                                Cancelar
                            </Button>
                            <Button onClick={handleDeleteCard} autoFocus>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {showTextField && (
                        <Box sx={{ mt: 2, position: 'relative' }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                // placeholder="Insira seu texto aqui..."
                                variant="outlined"
                                value={observacoes}
                                onChange={handleObsChange}
                                sx={{ fontSize: 10 }}
                            />
                            <IconButton
                                aria-label="close"
                                size='small'
                                color='red'
                                onClick={handleCloseClick}
                            // sx={{ position: 'relative', top: 8, right: 8 }}
                            >
                                <Tooltip title='Fechar sem salvar' placement='bottom'>
                                    <CloseIcon fontSize='inherit' sx={{ color: 'red' }} />
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                aria-label="close"
                                size='small'
                                color='blue'
                                onClick={handleSaveCloseClick}
                            // sx={{ position: 'relative', top: 8, right: 8 }}
                            >
                                <Tooltip title='Salvar e fechar' placement='bottom'>
                                    <CheckIcon fontSize='inherit' sx={{ color: 'blue' }} />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    )}
                    {
                        valorado && (
                            <Container>
                                <CurrencyEditableLabel initialText={valorContrato} onChange={handleValorContratoChange} />
                            </Container>
                        )
                    }
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <StarRating starColor='gold' stars={rating} onRatingChange={handleRatingChange} />
                        {
                            contato && (
                                <Tooltip title="Chamar no Whatsapp" placement='bottom'>
                                    <WhatsAppLink phoneNumber={contato.whatsapp} />
                                </Tooltip>
                            )
                        }
                    </Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center' >
                        <Typography>Abordagens:</Typography>
                        <CheckListAbordagem abordagens={abordagens} onAbordagensChange={handleAbordagensChange} />
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default Cartao