import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const CheckListAbordagem = ({ maxAbordagens = 5, abordagens = 0, onAbordagensChange }) => {
    const [checks, setChecks] = useState(abordagens)

    const handleAbordagem = (index) => {
        const novaAbordagem = index + 1;
        setChecks(novaAbordagem);
        if (onAbordagensChange) {
            onAbordagensChange(novaAbordagem);
        }
    }

    return (
        <Box display="flex">
          {[...Array(maxAbordagens)].map((_, index) => (
            <IconButton key={index} onClick={() => handleAbordagem(index)}>
              {index < checks ? <CheckCircleOutlinedIcon fontSize='small' /> : <RadioButtonUncheckedOutlinedIcon fontSize='small' />}
            </IconButton>
          ))}
        </Box>
      );
}

export default CheckListAbordagem;