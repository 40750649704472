import React from "react";
import { useNotifications } from "./NotificationProvider";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


function NotificationMenu({ notifications }) {
    const { removeNotification } = useNotifications();

    return (
        <List sx={{ padding: 0.5 }}>
            {notifications.length === 0 ? (
                <ListItem>
                    <ListItemText primary="Sem notificações" />
                </ListItem>
            ) : (
                notifications.map((notification) => (
                    <ListItem key={notification.id} secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={() => removeNotification(notification.id)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    }
                        sx={{
                            border: '1px solid rgba(0, 0, 0, 0.2)', // Borda ao redor da notificação
                            borderRadius: '4px', // Bordas arredondadas
                            marginBottom: '8px', // Espaço entre as notificações
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)', // Cor de destaque ao passar o mouse
                            },
                            '& .MuiListItemText-primary': {
                                fontSize: '0.875rem', // Diminuir o tamanho da fonte
                            },
                        }}
                    >
                        <ListItemText primary={
                            <Typography variant="body2">
                                <strong>{notification.title}:</strong> {notification.message}
                            </Typography>
                        } />
                    </ListItem>
                ))
            )}
        </List>
    );
}

export default NotificationMenu;
