import { Alert, Box, Button, Container, Snackbar, TextField, Typography } from "@mui/material";
import LightCRMLogo from '../images/octupuslogo-semfundo-roxo.png';
import { resetPassword } from "../services/SrvLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function EsqueciSenha() {

    const navigate = useNavigate()

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [serverity, setSeverity] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
        if (serverity === 'success') {
            navigate('/')
        }
    }

    const requestResetPassword = async (data) => {
        await resetPassword(data).then(
            (response) => {
                setSeverity('success')
                setAlertMessage(response['message'])
                setSnackbarOpen(true)
            },
            (error) => {
                setSeverity('error')
                setAlertMessage(error['message'])
                setSnackbarOpen(true)
            }
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(data.entries());

        // console.log('formJson: ', formJson)
        requestResetPassword(formJson)
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", border: "solid 2px", marginTop: 8, padding: 2, borderRadius: 5 }}>
                <Box component="img" src={LightCRMLogo} sx={{ marginTop: 2, marginBottom: 2, width: 224, height: 224 }} />
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Typography>
                        Informe o email cadastrado na plataforma para redefinir a senha:
                    </Typography>
                    <TextField
                        type="email"
                        size="small"
                        required
                        variant="outlined"
                        fullWidth
                        label="Email"
                        name="email"
                        id="email"
                        autoFocus
                        sx={{ mt: 2 }}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >Redefinir senha</Button>
                </Box>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={serverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Container>


    )
}