import { Alert, Box, Button, Container, Link, Snackbar, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
// import GoogleLogo from '../images/web_neutral_rd_na.svg'
import { useNavigate } from "react-router-dom";
import LightCRMLogo from '../images/octupuslogo-semfundo-roxo.png';
import { getHello, setLogin } from "../services/SrvLogin";

function Login() {

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [serverity, setSeverity] = useState('success')
  const [alertMessage, setAlertMessage] = useState('')

  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(data.entries());

    // console.log('formJson: ', formJson)

    setLogin(formJson['email'], formJson['senha'])
      .then((response) => {
        const token = response['token'];
        // const profile = getHello(token)
        setAlertMessage('Login efetuado com sucesso!')
        setSeverity('info')
        setSnackbarOpen(true)
        getHello(token)
          .then((resp_hello) => {
            navigate('/', { state: { 'token': token, 'profile': resp_hello, 'isAuthenticated': true } })
          },
            (error_hello) => {
              // console.log('erro_hello', error_hello);
              alert(`Erro ao recuperar profile do usuário: ${error_hello}`);
            }
          )
      },
        (error) => {
          setAlertMessage(error.response.data.non_field_errors[0])
          setSeverity('error')
          setSnackbarOpen(true)
          // console.log('erro: ', error)
        }
      )
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center", border: 'solid 2px', borderRadius: 5, padding: 2 }}>
        <Box component="img" src={LightCRMLogo} sx={{ marginTop: 2, marginBottom: 2, width: 224, height: 224 }} />
        {/* <Typography component="h1" variant="h5">
          Entrar
        </Typography> */}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            autoComplete="current-password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >Ir</Button>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Link href="/register" variant="body2">
              <Typography variant="caption" display="block" gutterBottom>
                Não tem cadastro? Cadastre-se!
              </Typography>
            </Link>
            <Link href="/esqueci" variant="body2">
              <Typography variant="caption" display="block" gutterBottom>
                Esqueci a senha
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleSnackbarClose} severity={serverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {/* <Box component='div' maxWidth='xs' sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="caption" display="block" gutterBottom>
          Ou faça login com o Google
        </Typography>
        <Button>
          <img src={GoogleLogo} alt="Login com o Google" />
        </Button>
      </Box> */}
    </Container>
  );
}

export default Login;